@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,500&display=swap');
@import url(./css/reset.css);
@import url(./css/utility.css);
@import url(./css/abstract.css);
@import url(./css/components.css);




:root {
  --clr-primary: #1f2839;
  --clr-accent: #e0b659;
  --clr-light: #f5f5ef;
  --ff-header: 'Roboto Slab', serif;
  --ff-body: 'IBM Plex Sans', sans-serif;
  --fs-section-header: 30px;
}

html,
body {
  overflow-x: hidden;
}

h1,
h2,
h3 {
  font-family: var(--ff-header);
}

p,
section,
aside {
  font-family: var(--ff-body);
}

.inner-page-subtitle {
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 4px;
  display: block;
  margin-bottom: 10px;
}

.whatwedo-page-header {
  text-align: center;
}

#card-slider {
  scroll-behavior: smooth;
}

/* Hero */

.hero-wrap {
  padding: 0 10px;
  padding-top: calc(86px + 40px);
  color: var(--clr-primary);
  background-color: var(--clr-light);

}

.hero-header-wrap {
  margin-bottom: 50px;
}

.hero-img-wrap {
  background-color: var(--clr-primary);
  height: 180px;
  margin-top: 260px;
  transform: translateX(-50%);
  left: 50%;
  position: relative;
  width: 100vw;

}

.hero-btn-wrap .primary-btn,
.hero-btn-wrap .accent-btn {
  width: 100%;
}

.hero-img {
  width: 250px;
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  margin-left: auto;
  border: 1px solid #111;
  margin-right: auto;
  box-shadow: 8px 12px var(--clr-accent);

}

.hero-title {
  font-size: 40px;
  line-height: 1.1;
  margin-bottom: 20px;
  max-width: 25ch;
}



.hero-subtitle {
  margin-bottom: 50px;
}

.hero-link {
  color: var(--clr-primary);
  /* text-decoration: none; */
  text-align: center;
  font-weight: 600;
  display: block;
  text-underline-offset: 5px;
}

.hero-link:hover {
  color: #000;
}

.hero-link-icon svg {
  width: 20px;
  height: 18px;
  fill: #000;
  margin-right: 5px;
}

/* what we do */

.whatwedo-wrap {
  padding: 50px 20px;
}

.whatwedo-title {
  font-size: var(--fs-section-header);
  margin-bottom: 15px;
}

.whatwedo-body .whatwedo-list-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 -20px;
}

.whatwedo-body .whatwedo-list-wrap .whatwedo-list-item {
  display: flex;
  aspect-ratio: 1/1;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid var(--clr-light);
  color: #fff;
  fill: #fff;
  cursor: pointer;
  max-width: 300px;

  font-weight: 600;
  background-color: var(--clr-primary);
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-color: var(--clr-accent);

  transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.whatwedo-body .whatwedo-list-wrap .whatwedo-list-item:hover {
  scale: 1.01;
}

.whatwedo-list-item .whatwedo-list-icon svg {
  width: 40px;
}

.whatwedo-list-item .whatwedo-list-title {
  margin-top: 10px;
  font-weight: 600;
  font-size: 14px;
}


/* Who we are */

.whoweare-wrap {
  padding: 0 20px;
}

.whoweare-title {
  font-size: var(--fs-section-header);
  margin-bottom: 15px;
}

.whoweare-img-wrap {
  width: 100%;
  margin: 40px 0 20px 0;
}

.whoweare-img {
  width: 450px;
  height: 320px;
  object-fit: cover;
  margin: 0 auto;
  border: 1px solid #111;
  box-shadow: -8px 8px var(--clr-accent);
}

/* Our Team  */

.section-wrap {
  padding: 40px 10px;
}

.section-title {
  font-size: var(--fs-section-header);
  margin-bottom: 15px;
}

.ourteam-card-wrap {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 30px;
  gap: 20px;
}

.ourteam-card {
  max-width: 400px;
  border: 1px solid #e1e1e1;
  padding: 20px;
  margin: 0 auto;
  border-left: 5px solid var(--clr-primary);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.ourteam-card-center {
  justify-self: center;
  grid-column: 1/-1;
}

.ourteam-card-new-col {
  grid-column: 1;
}

.card-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;

}

.card-subtitle {
  margin-bottom: 15px;
  display: block;
}



/* Contact Us */
.contactus-wrap {
  background-color: var(--clr-light);
}

.contactus-wrap a {
  color: var(--clr-primary);
  text-decoration: none;
}

.contactus-wrap svg {
  width: 13px;
  margin-right: 3px;
}

.contactus-wrap .contactus-info {
  margin-bottom: 8px;
  font-size: 14px;
}

/* footer */

.footer-top {
  background-color: var(--clr-primary);
  color: #fff;
  padding: 40px 0;
  margin: 0px;

}


.footer-brand .footer-brand-img {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  /* color:; */
  fill: #fff;
}

.footer-brand-text {
  font-family: var(--ff-header);
  margin-top: 10px;
  color: #fff;
  line-height: 1.3;
}

.footer-brand-wrap {
  display: flex;
  justify-content: center;
}

.footer-brand {
  text-decoration: none;
  font-family: var(--ff-header);
  color: var(--clr-primary);
  font-size: 20px;
}

.footer-brand:hover {
  color: var(--clr-primary);
}

.footer-bottom {
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 20px;
}

.footer-nav {
  text-align: center;
  margin-top: 30px;

}

.footer-brands svg {
  width: 25px;
  fill: #fff;
}

.nav-link {
  padding: 5px;
}

.nav-link.active {
  font-weight: 500;
}

.footer-contact-us {
  position: relative;
}

.footer-contact-us .footer-form-btn {
  width: auto !important;
  margin: 0px;
  top: 0px;
  right: 0px;
  position: absolute;
  height: 38px;
  display: flex;
  align-items: center;
}

.footer-contact-us .footer-form-btn svg {
  width: 25px;
  fill: #fff;
  /* transform: rotate(-30deg); */
}

/* What we do */

/* .whatwedo-hero-wrap {
  padding: 100px 0 40px 0;
  background-color: #fff;
} */

.whatwedo-hero-wrap {
  padding: 100px 0 90px 0;
  background-image: linear-gradient(rgba(11, 25, 48, .7), rgba(11, 25, 48, .7)), url('../src1/images/bgImg.webp');
  background-position: 0 0, 50% 100%;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
  background-attachment: scroll, fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 260px;
  display: flex;
  color: #fff;
}




/* Benefits  */
.benefits-wrap {
  background-color: var(--clr-light);
}

.benefits-wrap .benefits-content {
  margin-top: 30px;
  /* padding-left: 30px; */
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  overflow: auto;
}

.benefits-wrap .benefits-content::-webkit-scrollbar {
  height: 5px;
}

/* .benefits-wrap .benefits-content {
  scrollbar-width: thin;
} */

.benefits-wrap .benefits-content::-webkit-scrollbar-track {
  background: transparent;
  border: 5px solid transparent;
}

.benefits-wrap .benefits-content::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 6px;
  background-clip: content-box;

  border: 5px solid transparent;
}



.benefits-wrap .benefits-content .benefits-card {
  min-width: 280px;
  border: 1px solid #c1c1c1;
  border-radius: 6px;
  aspect-ratio: 1/1;
  background-color: #fff;
  text-align: left;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.benefits-wrap .benefits-content .benefits-card .benefits-card-content {
  padding: 25px;
}

.benefits-wrap .benefits-content .benefits-card .benefits-card-content .benefits-card-title {
  font-size: 20px;
  font-weight: 900;
  padding-bottom: 15px;
}






/* Who we are page */
.ourvalues-wrap {
  background-color: var(--clr-primary);
  color: white;
}

.ourvalues-header-wrap {
  text-align: center;
}

.ourvalues-card {
  border-bottom: 1px solid #a1a1a1;
  padding-bottom: 30px;
  text-align: center;
  height: 100%;
  max-width: 450px;
}

.ourvalues-top-wrap {
  width: 100%;
  padding: 15px 0;
  position: relative;
  display: flex;
  flex-direction: column;
}

.ourvalues-number {
  font-size: 60px;
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--clr-accent);
}

.ourvalues-overlap {
  font-size: 25px;
  font-weight: 500;
  position: absolute;
  left: 50%;
  top: 65px;
  transform: translateX(-50%);
}

.ourvalues-text {
  color: #d8d8d8;
}



/* OUR VISION */
.ourvision-wrap {
  background-color: var(--clr-light);
  border-radius: 10px;
}


/* Why CHoose US */
/*
.whychooseus-wrap {
   background-color: var(--clr-light);
}
 */

.whychooseus-header-wrap {
  text-align: center;
  margin-bottom: 50px;
}

.whychooseus-card-wrap {

  max-width: 450px;
  margin: 0 auto;
}

.whychooseus-card-wrap .whychooseus-card {
  display: flex;
  border-bottom: 1px solid var(--clr-accent);
  padding: 15px 0;
  align-items: center;
  margin: 0 15px;
}

.whychooseus-card-icon {
  width: 40px;
  height: 40px;
  background-color: rgba(224, 182, 89, 0.5);
  text-align: center;
  margin-right: 15px;
  border-radius: 100vw;
  color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whychooseus-card-icon svg {
  width: 23px;
  height: 23px;
  fill: var(--clr-primary);
}

.whychooseus-card-title {
  font-size: 18px;
  color: #333;
}


/* Stages of Work */

.stagesofwork-body {
  max-width: 800px;
  margin: 0 auto;
}

.stagesofwork-header-wrap {
  margin-bottom: 30px;
}


.stagesofwork-card {
  padding: 20px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  position: relative;
  margin-bottom: 30px;
}

.stagesofwork-card::before {
  position: absolute;
  content: '';
  width: 10px;
  height: 10px;
  border: 2px solid var(--clr-accent);
  border-radius: 100px;
  background-color: #fff;
  top: calc(100% + 10px);
  z-index: 12;
  left: calc(50% - 4px);
}

.stagesofwork-card::after {
  position: absolute;
  content: '';
  width: 2px;
  height: 30px;
  background-color: var(--clr-primary);
  top: 100%;
  left: 50%;
}

.stagesofwork-card:last-child:after,
.stagesofwork-card:last-child::before {
  content: unset;
}


.stagesofwork-card .stagesofwork-subtitle {
  font-size: 14px;
  font-weight: 500;
  color: #a1a1a1;
}

.stagesofwork-card .stagesofwork-title {
  font-size: 17px;
  margin-top: 5px;
  margin-bottom: 15px;
  width: fit-content;
}

.stagesofwork-card .stagesofwork-body ul {
  list-style: disc;
  padding-left: 20px;
}

.stagesofwork-card .stagesofwork-body ul li {
  color: #444;
}


.ourservices-card {
  border: 1px solid #e1e1e1;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: grid;
  grid-template-columns: 50px auto;
  column-gap: 1rem;
  /* flex-direction: row; */
  align-items: start;
  /* max-width: 450px; */

  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.ourservices-header-wrap {
  margin-bottom: 20px;
}

.ourservices-card-icon {
  width: 50px !important;
  height: 50px;
  background-color: var(--clr-light);
  border-radius: 100vw;
  fill: var(--clr-accent);
  padding: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ourservices-card-title {
  font-size: 20px;
}

.ourservices-card-subtitle {
  color: #555;
  margin-bottom: 15px;
}

.ourservices-card-link {
  color: var(--clr-accent);
  text-decoration: none;
  font-size: 14px;
  border-bottom: 1px solid var(--clr-primary);
  transition: all 0.2s ease;
}

.ourservices-card-link:hover {
  font-size: 15px;
  border-bottom: 2px solid var(--clr-primary);
  color: var(--clr-accent);
  font-weight: 600;
}


.services-section-wrap .services-body-text {
  font-size: 17px;
  margin-bottom: 15px;
  margin-top: 15px;
  color: #1a1a1a;

}


.services-moreservice-wrap {
  padding: 30px;
  background-color: var(--clr-light);
  margin-top: 40px;
}

.services-moreservice-title {
  font-size: 18px;
  font-weight: 700;
}

.services-moreservice-body li {
  padding: 7px 0;
}

.services-moreservice-body li a {
  color: var(--clr-primary);
  text-decoration: none;
  border-bottom: 1px solid var(--clr-accent);
}

.services-section-wrap {
  max-width: 1200px;
  margin: 0 auto;
}

.services-section-img {
  margin: 30px auto;
}

.ourprojects-section-cards {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.ourprojects-section-card {
  max-width: 100%;
  padding: 20px;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

}

.ourprojects-section-card b {
  color: #333;
  font-size: 14px;
}

.ourprojects-card-title {
  font-size: 17px;
  margin: 0px;
  padding-bottom: 10px;
}

.ourprojects-card-subtitle {
  color: #555;
  margin-bottom: 8px;
}

.ourprojects-card-details {
  margin-bottom: 10px;
}

.ourprojects-card-scope {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.ourprojects-card-item {
  background-color: #4e73b8;
  color: #fff;
  padding: 3px 5px;
  display: block;
  width: fit-content;
  border-radius: 5px;
  font-size: 13px;
  text-transform: capitalize;
}

.ourprojects-section-search {
  max-width: 500px;
  margin-left: auto;
}

.social-link {
  cursor: pointer;
}

.logo-registered svg {
  height: 15px;
  width: 20px;
  position: absolute;
  top: 0;
  right: -20px;
  fill: var(--clr-primary);
}

.navbar-brand-text.text-light .logo-registered svg {
  fill: #fff
}

.fill-white svg {
  fill: #fff
}

.services-body-text b {
  border-bottom: 1px solid var(--clr-accent);
}

.services-body-list {
  padding-bottom: 10px;
  line-height: 1.6;
}


/* xs */
@media (min-width:375px) {
  .contactus-wrap .contactus-info {
    font-size: 16px;
  }
}

/* sx */
@media (min-width:640px) {
  .hero-btn-wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .hero-btn-wrap .primary-btn {
    margin-bottom: 0px !important;
    margin-right: 20px;
  }


  .hero-img {
    width: 280px;
  }
}


/* md */
@media (min-width:768px) {

  .whatwedo-body .whatwedo-list-wrap {
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 -25px;
  }

  .w-lg-50 {
    width: 50% !important;
  }



  .ourservices-card {
    max-width: 500px;
  }

  .services-section-grid {
    display: grid;
    grid-template-columns: 3fr 300px;
    column-gap: 30px;
    /* position: relative; */

  }

  .services-moreservice-wrap {
    position: sticky;
    top: 110px;
    position: -webkit-sticky;
    height: fit-content;
    margin-top: 0px;

  }

  .stagesofwork-card .stagesofwork-title {
    border-bottom: 2px solid var(--clr-accent);
  }

  .services-section-wrap .services-body-text {
    font-size: 18px;
    color: #444;
  }

  .inner-page-subtitle {
    font-size: 16px;
  }

  .ourprojects-section-cards {
    grid-template-columns: 1fr 1fr;
  }

}

/* lg */
@media (min-width:991px) {
  .section-wrap {
    padding: 70px 0px;
  }

  .section-title {
    font-size: 40px;
    margin-bottom: 25px;
  }

  .whoweare-img-wrap {
    margin: 0px;

  }

  .whoweare-img {
    height: auto;
    width: 100%;
  }

  .ourteam-header-wrap {
    width: 600px;
    margin: 0 auto;
  }

  .whoweare-header-wrap {
    max-width: 650px
  }

  .contactus-header-wrap {
    width: 500px;
  }

  .footer-brand {
    display: flex;
    align-items: center;
    height: 100px;
  }

  .footer-nav {
    margin-top: 0px;
  }

  .hero-wrap {
    padding-top: 170px;
  }

  .hero-header-wrap {
    margin-right: 100px;

  }

  .hero-grid {
    display: grid;
    grid-template-columns: 2.5fr 1.5fr;
  }

  .hero-img-wrap {
    height: calc(100% + 170px);
    margin-top: -170px;
  }

  .hero-img {
    margin: 0;
    left: -30px;
    width: 300px;

  }

  .hero-btn-wrap .primary-btn,
  .hero-btn-wrap .accent-btn {
    width: 220px;
  }

  .stagesofwork-card::before {
    position: absolute;
    content: '';
    width: 15px;
    height: 15px;
    border: 2px solid var(--clr-primary);
    border-radius: 3px;
    background-color: #fff;
    top: 50%;
    z-index: 12;
    left: -33px;
  }

  .stagesofwork-card::after {
    position: absolute;
    content: '';
    width: 1px;
    height: calc(100% + 100px);
    background-color: var(--clr-primary);
    top: 50%;
    left: -27px;
  }

  .stagesofwork-card:nth-child(3)::after {
    height: calc(100% + 20px);
  }

  .stagesofwork-card:last-child::before {
    content: '';
  }


  .ourservices-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .ourservices-card {
    margin: 0px;
    max-width: 450px;
    justify-self: end;
  }

  .ourservices-card:nth-child(2n) {
    justify-self: start;
  }

  .services-section-grid {
    column-gap: 50px;
  }


  .services-body-list {
    padding-bottom: 15px;
    line-height: 1.6;
  }

}

/* xl */
@media (min-width:1024px) {
  .ourteam-card-wrap {
    width: 950px;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: 1fr 1fr;
    margin-top: 50px;
    gap: 30px;
  }

  .ourteam-card {
    width: auto;
    max-width: unset;
  }

  .ourteam-card-center {
    max-width: 455px;
  }

  .hero-img-wrap {
    left: 55%;
  }

  .benefits-wrap {
    text-align: center;
    gap: 30px;
  }

  .section-subtitle {
    width: 70ch;
    margin: 0 auto;
  }


  .whychooseus-card-wrap {
    display: grid;
    max-width: unset;
    grid-template-columns: repeat(4, 1fr);
  }

  .ourprojects-section-cards {
    grid-template-columns: 1fr 1fr 1fr;
  }
}


/* 2xl */
@media (min-width:1280px) {
  .ourteam-card-wrap {
    width: 1030px;
  }

  .ourteam-card,
  .ourteam-card-center {
    max-width: 500px;
  }

  .hero-img-wrap {
    left: 70%;
  }
}