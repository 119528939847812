.primary-btn {
  background-color: var(--clr-accent);
  color: #fff;
  font-weight: 600;
  border-radius: 0px;
  outline: none;
  border: 1px solid var(--clr-accent);
  padding: 7px 14px;
  text-decoration: none;
}

/* .primary-btn:active,
.primary-btn:focus,
.primary-btn:visited { */
/* background-color: var(--clr-light) !important; */
/* border: 2px solid var(--clr-accent) !important; */
/* } */


.accent-btn {
  display: block;
  background-color: white;
  color: var(--clr-primary) !important;
  font-weight: 600;
  border-radius: 0px;
  outline: none;
  border: 1px solid var(--clr-primary);
  padding: 7px 14px;
  text-align: center;
  text-decoration: none;
}

/* .accent-btn:active,
.accent-btn:focus,
.accent-btn:visited { */
/* color: white; */
/* background-color: var(--clr-primary) ; */
/* border: 2px solid var(--clr-accent) !important; */
/* } */


.btn-outline {
  border: 1px solid var(--clr-primary);
  color: var(--clr-primary);
  transition: all 100ms linear;
}

.btn-outline:hover {
  border-color: var(--clr-primary);
  box-shadow: 0 0 0 2px rgba(13, 110, 253, .25)
}


.btn-outline svg {
  width: 20px;
  height: 20px;
  fill: var(--clr-primary);
}