.navbar-wrap {
  padding: 0;
  transition: all 150ms linear;
}

.scroll {
  background-color: #fff;
  /* border-bottom: 1px solid #e1e1e1; */
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
}

.navbar-brand-text {
  color: var(--clr-primary);
  font-family: var(--ff-header);
  margin-left: 10px;
  line-height: 1.1;
  font-size: 17px;

}

.navbar-wrap .navbar-handler svg {
  width: 25px;
}

.navbar-toggler {
  border: none;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-wrap img {
  width: 40px;

}

.nav-item .nav-link {
  color: #fff;
  margin: 0px;
}

.nav-link.active {
  color: #fff;
}

.nav-item .nav-link {

  padding-right: 30px !important;

}

.nav-item .nav-link .primary-btn {
  width: auto;
  height: 35px;
  text-align: center;
  display: flex;
  padding: 10px;
  align-items: center;

}

.navbar-wrap.fixed-top .navbar-nav {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  padding: 30px;
  padding-left: 50px;
  align-items: center;
  position: relative;
}

.navbar-wrap.fixed-top.scroll .navbar-nav {
  background-color: #fff;
  /* color: black; */
}

.navbar-wrap.fixed-top.scroll .nav-item .nav-link {
  color: #000;
  margin: 0px;
}

.navbar-wrap.fixed-top .navbar-nav::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 300px;
  top: 0;
  right: -300px;
  background-color: rgba(0, 0, 0, 0.5);
}

.navbar-wrap.fixed-top.scroll .navbar-nav::after {
  background-color: #fff;
}

/* .navbar-collapse.navbar-visible */
/* .navbar-collapse {} */

.navbar-collapse.navbar-hidden {
  /* opacity: 0; */
  height: 0vh;
  /* display: block; */
  transition: all 0.1s linear;
}

.navbar-hidden .navbar-nav {
  display: none;
  transition: all 0.2s;
}

.navbar-collapse.navbar-visible {
  display: block;
  width: 100vw;
  position: absolute;
  top: 65px;
  left: 0;
  right: 0;
  height: 100vh;
  opacity: 1;
  transition: all 0.1s linear;

}

.navbar-collapse.navbar-visible .navbar-nav {
  background-color: #fff;
  transition: all 0.2s;
  padding-left: 12px;
  display: block;

}


.navbar-collapse.navbar-visible .nav-item .nav-link {
  padding-bottom: 20px;
  color: var(--clr-primary);
  font-weight: 500;
}


@media (min-width:768px) {
  .navbar-wrap img {
    width: 60px;
  }

  .navbar-brand-text {
    font-size: 24px;
  }

  .navbar-collapse.navbar-visible {
    top: 94px;
  }

}

@media (min-width:991px) {
  .navbar-collapse.navbar-hidden {
    height: unset;
  }

  .navbar-hidden .navbar-nav {
    display: flex;
  }


}

.navbar-toggler:focus {
  box-shadow: none;
}

/* Icon 1 */

#nav-icon1,
#nav-icon2,
#nav-icon3,
#nav-icon4 {
  width: 35px;
  height: 30px;
  position: relative;
  margin: 0 auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

.white-navbar #nav-icon1 span,
.white-navbar #nav-icon3 span,
.white-navbar #nav-icon4 span {
  background: #fff;

}

#nav-icon1 span,
#nav-icon3 span,
#nav-icon4 span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: var(--clr-primary);
  border-radius: 2px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}


/* Icon 3 */

#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
  top: 10px;
}

#nav-icon3 span:nth-child(4) {
  top: 20px;
}

#nav-icon3.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}



.modal_wrap {

  position: fixed;
  z-index: 100000000;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
}

.modal_content {
  border-radius: 10px;
  max-width: 400px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding: 0 50px;
  white-space: nowrap;
}

.modal_content.success {
  background-color: #dde8de;
  color: #46734c;
  border: 1px solid #b8ccba;
}

.modal_content.fail {
  background-color: #e8dddd;
  color: #734646;
  border: 1px solid #ccb8b8;
}

.modal_open {
  overflow: hidden;

}