.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.5rem;
  padding-left: 0.5rem;
}

.text-accent {
  color: var(--clr-accent);
}

.text-primary {
  color: var(--clr-primary) !important;
}

.hide-scroll {
  margin: 0;
  height: 100%;
  overflow: hidden
}

/* .bgr-light {
  background-color: var(--clr-primary);
} */

/* .align-self-center {
align-self:center ;
} */